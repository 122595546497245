/* Pretendard Regular */
@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url('https://cdn.rememberapp.co.kr/assets/font/Pretendard-Regular.subset.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://cdn.rememberapp.co.kr/assets/font/Pretendard-Regular.subset.woff')
      format('woff');
}

/* Pretendard Medium */
@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: url('https://cdn.rememberapp.co.kr/assets/font/Pretendard-Medium.subset.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://cdn.rememberapp.co.kr/assets/font/Pretendard-Medium.subset.woff')
      format('woff');
}

/* Pretendard SemiBold */
@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src: url('https://cdn.rememberapp.co.kr/assets/font/Pretendard-SemiBold.subset.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://cdn.rememberapp.co.kr/assets/font/Pretendard-SemiBold.subset.woff')
      format('woff');
}
html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  font-family: 'Pretendard', sans-serif;
  background-color: #ffffff;
}

#__next {
  min-height: 100vh;
}

body {
  font-family: 'Pretendard', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  font-family: 'Pretendard', sans-serif;
}

textarea {
  font-family: 'Pretendard', sans-serif;
}

@media only screen and (max-width: 768px) {
  .ReactModalPortal > .dc-modal-overlay--open > .dc-modal {
    min-width: 90vw;
    max-width: 90vw;
  }
}

/*
  input type number 우측 arrow hide
  https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* 모바일에서 input box 상단 shadow 삭제 */
input[type='text']::-ms-clear {
  display: none;
}

input {
  -webkit-appearance: none;
}
