$red-100: #ea5128;
$gray-450: #222;

@mixin font($font: 12px, $font-weight: normal, $color: black, $opacity: false) {
  font-size: $font;
  font-weight: $font-weight;
  color: $color;

  @if $opacity {
    opacity: $opacity;
  }

  line-height: normal;
}

@mixin text--s($font-weight: normal, $color: black, $opacity: false) {
  @include font(14px, $font-weight, $color, $opacity);
}

@mixin text--break {
  word-break: break-all;
  word-wrap: break-word;
}

.Toastify__toast-container--top-center {
  top: 66px !important;
  margin-left: 0px !important;
  transform: translateX(-50%) !important;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--top-center {
    left: 0px;
    right: 0px;
    margin: 0 auto;
    transform: translateX(0%) !important;
  }

  .DcToast {
    width: 80%;
    margin: 0 auto;
  }
}

.Toastify__toast-container {
  width: unset !important;
}

div .DcToast {
  padding: 15px 28px;
  border-radius: 6px;
  background-color: $gray-450;
  min-height: 0;
  min-width: 324px;
  opacity: 0.9;
  display: flex;
  justify-content: center;

  // toast Frame Styling
  .Toastify__toast-body {
    margin: 0;
    @include text--s(normal, white);
    @include text--break;
    text-align: center;
  }
}

div .DcToastError {
  padding: 15px 28px;
  border-radius: 6px;
  background-color: $red-100;
  min-height: 0;
  min-width: 324px;
  opacity: 0.9;
  display: flex;
  justify-content: center;

  .Toastify__toast-body {
    margin: 0;
    @include text--s(normal, white);
    @include text--break;
    text-align: center;
  }
}

div .DcToastDark {
  padding: 15px 28px;
  border-radius: 6px;
  background-color: #fff;
  min-height: 0;
  min-width: 324px;
  opacity: 0.9;
  display: flex;
  justify-content: center;

  .Toastify__toast-body {
    margin: 0;
    @include text--s(normal, #000);
    @include text--break;
    text-align: center;
  }
}
